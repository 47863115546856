import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress
import Cookies from "js-cookie";
import axios from "axios";
import AppBars from "../AppBar/AppBar";
import Listitems from "../ListItems/ListItems";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { GlobalUrl, DrawerHeader, Drawer } from "../Global";

export default function DataSet() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("sFrontToken");
        if (!token) {
          throw new Error("Access token is not available");
        }

        const response = await axios({
          method: "get",
          url: `${GlobalUrl}/doc/dataset-list?private=false`, // Send as a query parameter
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setData(sortedData); // Set the 'data' from the response
      } catch (err) {
        setError(err.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === "Private Collections") {
      navigate("/dataset/list/private");
    }
    if (text === "Collections") {
      navigate("/dataset/list/public");
    }
    if (text === "Upload New Dataset") {
      navigate("/upload");
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  const handleUpload = () => {
    navigate("/upload");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: { xs: "100%", sm: open ? 240 : 60 }, // Full width on small screens, reduced width when collapsed
          transition: "width 0.3s",
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "#FFFFFF" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#EEEEEE",
          height: "100vh", // Full viewport height
          overflowY: "auto", // Enable scrolling
        }}
      >
        <DrawerHeader />

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {error && <Typography color="error">Error: {error}</Typography>}
            {data && data.length > 0 ? (
              <Box
                sx={{
                  paddingRight: "16px",
                  marginLeft: "30px",
                }}
              >
                <Grid container spacing={2}>
                  {data.map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                      <Card
                        sx={{
                          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                          textAlign: "center",
                          padding: 2,
                          backgroundColor:
                            item.type === "DOC_Query" ? "#F9FAFB" : "#ffffff",
                          width: "100%", // Full width for responsiveness
                          height: { xs: "180px", md: "220px" }, // Square-like shape
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center", // Center content vertically
                          alignItems: "center",
                          borderRadius: 2,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(
                            `/collection/${encodeURIComponent(
                              item.dataset_name
                            )}/${item.type}`
                          )
                        }
                      >
                        <CardContent
                          sx={{
                            width: "100%",
                            maxHeight: "100%", // Keep content within bounds
                            overflow: "hidden", // Hide any overflow
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: { xs: "16px", md: "18px" },
                              color: "#4a4a4a",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              marginBottom: 1,
                              whiteSpace: "normal",
                              wordWrap: "break-word", // Allows long text to wrap
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2, // Limit to 2 lines
                            }}
                          >
                            {item.dataset_name}
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: { xs: "14px", md: "16px" },
                              color: "#666",
                              marginTop: 1,
                            }}
                          >
                            {new Date(item.created_at).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "2-digit",
                              }
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Card sx={{ padding: 4, maxWidth: 400, textAlign: "center" }}>
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      No data set right now. Please upload some.
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpload}
                    >
                      Upload Data
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
