import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid"; // Import Grid component from Material-UI
import AppBars from "../AppBar/AppBar";
import Listitems from "../ListItems/ListItems";
import { useNavigate } from "react-router-dom";
import { GlobalUrl, DrawerHeader, Drawer } from "../Global";
import Cookies from "js-cookie";
import axios from "axios";
import CardActions from "@mui/material/CardActions";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

// Expandable Card Component
const ExpandableCard = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const token = Cookies.get("sFrontToken");
      if (!token) {
        throw new Error("Access token is not available");
      }

      const response = await axios({
        method: "get",
        url: `${GlobalUrl}/doc/get-pinned-dataset`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const sortedData = response.data.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setData(sortedData); // Set the 'data' from the response
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCardClicked = (item) => {
    navigate(`/dataset/notes/${item.id}/${item.dataset_name}`);
  };

  return (
    <>
      <Grid container spacing={2}>
        {" "}
        {/* Grid container for the row layout */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Full viewport height
              overflowY: "auto", // Enable scrolling
            }}
          >
            <CircularProgress /> {/* Loader spinner */}
          </Box>
        ) : (
          <>
            {data?.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                {" "}
                {/* 3 cards per row on medium and above */}
                <Card
                  sx={{
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                    textAlign: "center",
                    padding: 2,
                    backgroundColor:
                      item.type === "DOC_Query" ? "#F9FAFB" : "#ffffff",
                    width: "100%", // Full width for responsiveness
                    height: { xs: "180px", md: "220px" }, // Square-like shape
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center", // Center content vertically
                    alignItems: "center",
                    borderRadius: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleCardClicked(item);
                  }}
                >
                  <CardContent
                    sx={{
                      width: "100%",
                      maxHeight: "100%", // Keep content within bounds
                      overflow: "hidden", // Hide any overflow
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        color: "#4a4a4a",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        marginBottom: 1,
                        whiteSpace: "normal",
                        wordWrap: "break-word", // Allows long text to wrap
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2, // Limit to 2 lines
                      }}
                      variant="h6"
                    >
                      {item.dataset_name}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center", // Ensure the button and icons/data are spaced apart
                      alignItems: "center", // Align vertically
                      width: "100%", // Full width of the card
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1, // Space between icons or elements
                      }}
                    >
                      {new Date(item.created_at).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default function Notes() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === "Private Collections") {
      navigate("/dataset/list/private");
    }
    if (text === "Collections") {
      navigate("/dataset/list/public");
    }
    if (text === "Upload New Dataset") {
      navigate("/upload");
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#EEEEEE",
          minHeight: "calc(100vh - 0px)",
        }}
      >
        <DrawerHeader />
        {/* Render the cards in a grid layout */}
        <ExpandableCard />
      </Box>
    </Box>
  );
}
